/**
 * Page is used for generated pages (agency, team etc..)
 * It is built from gatsby-node.js
 * */
import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Slices from '../components/slices'

interface PageProps {
  data: any
  pageContext: {
    locale: string
    name: string
  }
}

const Page: React.FC<PageProps> = ({
  data: { prismicHeader, prismicFooter, prismicPage }, // from query below
  pageContext, // from gatsby-node.js
}) => {
  // just a log for 3D performances
  useEffect(() => {
    return () => {
      console.log('unmount page')
    }
  }, [])

  return (
    <Layout
      pageContext={pageContext}
      headerData={prismicHeader.data}
      footerData={prismicFooter.data}
    >
      <SEO title={prismicPage.data.title.text} />
      <Slices data={prismicPage.data.body} />
    </Layout>
  )
}

export default Page

// uid and locale are transmited from createPages in gatsby-node
export const query = graphql`
  query PageQuery($uid: String!, $locale: String!) {
    prismicHeader(lang: { eq: $locale }) {
      ...HeaderQuery
    }

    prismicFooter(lang: { eq: $locale }) {
      ...FooterQuery
    }

    prismicPage(lang: { eq: $locale }, uid: { eq: $uid }) {
      data {
        title {
          text
        }
        body {
          ...SlicesQuery
        }
      }
    }
  }
`
