import React from 'react'
import { graphql } from 'gatsby'
import Text from './Text'
import ImageText from './ImageText'

interface SlicesProps {
  data: {
    id: string
    slice_type: string
    primary: any
  }[]
}

const Slices: React.FC<SlicesProps> = ({ data }) => {
  return data.map(item => {
    // Use right component according to slice type
    // TODO : replace with enum
    switch (item.slice_type) {
      case 'text':
        return <Text key={item.id} {...item.primary} />

      case 'image_text':
        return <ImageText key={item.id} {...item.primary} />

      default:
        return (
          <div key={item.id} style={{ color: 'red' }}>
            <details>
              <summary>No component for slice {item.slice_type}</summary>
              You should create it in <code>src/components/Slices/</code>
              <br />
              Here is its data:
              <pre>{JSON.stringify(item.primary, null, 2)}</pre>
            </details>
          </div>
        )
    }
  })
}

export default Slices

export const query = graphql`
  fragment SlicesQuery on PrismicPageBodyImageTextPrismicPageBodyTextUnion {
    ...TextQuery
    ...ImageTextQuery
  }
`
