import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

const Container = styled.div`
  padding: 16px;
  margin: 16px 0;
  background: #f5f5f5;
  border-radius: 6px;
`

interface TextProps {
  text: {
    html: string
  }
}

const Text: React.FC<TextProps> = ({ text }) => (
  <Container dangerouslySetInnerHTML={{ __html: text.html }} />
)

export default Text

export const query = graphql`
  fragment TextQuery on PrismicPageBodyText {
    id
    slice_type
    primary {
      text {
        html
      }
    }
  }
`
