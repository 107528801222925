import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

enum ImagePosition {
  top = 'top',
  right = 'right',
  left = 'left',
  bottom = 'bottom',
}
interface ImageTextProps {
  image_position: ImagePosition
  text: {
    html: string
  }
  image: {
    alt: string
    url: string
  }
}

const Container = styled.div`
  display: flex;
  padding: 16px;
  margin: 16px 0;
  background: #f5f5f5;
  border-radius: 6px;
  
  &[data-image-position=${ImagePosition.top}],
  &[data-image-position=${ImagePosition.bottom}] {
    flex-direction: column;
  }
`

const Image = styled.img`
  &[data-image-position=${ImagePosition.top}],
  &[data-image-position=${ImagePosition.bottom}] {
    display: block;
    object-fit: cover;
  }
  
  &[data-image-position=${ImagePosition.left}],
  &[data-image-position=${ImagePosition.right}] {
    object-fit: contain;
  }

  &[data-image-position=${ImagePosition.right}],
  &[data-image-position=${ImagePosition.bottom}] {
    order: 1;
  }
`

const Content = styled.div`
  &[data-image-position=${ImagePosition.left}] {
    margin-left: 16px;
  }
  
  &[data-image-position=${ImagePosition.right}] {
    margin-right: 16px;
  }
`

const ImageText: React.FC<ImageTextProps> = ({
  image_position: imagePosition,
  text,
  image,
}) => (
  <Container data-image-position={imagePosition}>
    <Image
      src={image.url}
      alt={image.alt}
      data-image-position={imagePosition}
    />
    <Content
      dangerouslySetInnerHTML={{ __html: text.html }}
      data-image-position={imagePosition}
    />
  </Container>
)

export default ImageText

export const query = graphql`
  fragment ImageTextQuery on PrismicPageBodyImageText {
    id
    slice_type
    primary {
      image {
        url
        alt
      }
      text {
        html
      }
      image_position
    }
  }
`
